// components/Dashboard/Tabs.jsx

import React from 'react';

function Tabs({ selectedTab, setSelectedTab }) {
  return (
    <div className="dashboard-tabs">
      <button
        className={`tab-button ${selectedTab === 'Now Playing' ? 'active' : ''}`}
        onClick={() => setSelectedTab('Now Playing')}
      >
        Now Playing
      </button>
      <button
        className={`tab-button ${selectedTab === 'Player Settings' ? 'active' : ''}`}
        onClick={() => setSelectedTab('Player Settings')}
      >
        Player Settings
      </button>
    </div>
  );
}

export default Tabs;
