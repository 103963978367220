// components/Overlays/Overlays.jsx

import React, { useState, useEffect } from 'react';
import './Overlays.css';
import axios from 'axios';

function Overlays({ selectedDevice }) {
  const [overlays, setOverlays] = useState([]);
  const [selectedOverlay, setSelectedOverlay] = useState(null);
  const [overlayText, setOverlayText] = useState('');
  const [overlayURL, setOverlayURL] = useState('');
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!selectedDevice) return;

    const fetchOverlays = async () => {
      try {
        const response = await axios.get('/api/overlays', { // Updated API path
          headers: { Authorization: `Bearer ${token}` },
        });
        setOverlays(response.data);
      } catch (error) {
        console.error('Error fetching overlays:', error);
        alert('Failed to fetch overlays.');
      }
    };

    fetchOverlays();
  }, [selectedDevice, token]);

  const handleOverlaySelect = (overlay) => {
    setSelectedOverlay(overlay);
    setOverlayText(overlay.text);
    setOverlayURL(overlay.url || '');
  };

  const handleSaveOverlay = async () => {
    if (!selectedOverlay) return;

    const updatedOverlay = {
      ...selectedOverlay,
      text: overlayText,
      url: overlayURL,
    };

    try {
      await axios.put(`/api/overlays/${selectedOverlay.id}`, updatedOverlay, { // Updated API path
        headers: { Authorization: `Bearer ${token}` },
      });
      setOverlays(
        overlays.map((overlay) =>
          overlay.id === selectedOverlay.id ? updatedOverlay : overlay
        )
      );
      alert('Overlay updated successfully!');
    } catch (error) {
      console.error('Error updating overlay:', error);
      alert('Failed to update overlay.');
    }
  };

  return (
    <div className="overlays-section">
      <h3>Overlays</h3>
      <div className="overlays-container">
        <div className="overlays-list">
          <h4>Existing Overlays</h4>
          <ul>
            {overlays.map((overlay) => (
              <li
                key={overlay.id}
                className={
                  selectedOverlay && selectedOverlay.id === overlay.id
                    ? 'selected'
                    : ''
                }
                onClick={() => handleOverlaySelect(overlay)}
              >
                {overlay.text}
              </li>
            ))}
          </ul>
        </div>

        <div className="overlay-details">
          {selectedOverlay ? (
            <>
              <h4>Edit Overlay</h4>
              <textarea
                placeholder="Enter overlay text here..."
                value={overlayText}
                onChange={(e) => setOverlayText(e.target.value)}
              />
              <button onClick={() => setIsQRCodeModalOpen(true)}>
                {overlayURL ? 'Edit QR Code' : 'Generate QR Code'}
              </button>
              <div className="overlay-buttons">
                <button onClick={handleSaveOverlay}>Save Overlay</button>
                <button
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to remove this overlay?'
                      )
                    ) {
                      // Implement remove functionality if needed
                      alert('Remove functionality not implemented yet.');
                    }
                  }}
                  className="remove-button"
                >
                  Remove Overlay
                </button>
              </div>
            </>
          ) : (
            <p>Select an overlay to edit.</p>
          )}
        </div>
      </div>

      {isQRCodeModalOpen && (
        <div className="qr-code-modal">
          <div className="qr-code-content">
            <h4>{overlayURL ? 'Edit QR Code' : 'Generate QR Code'}</h4>
            <input
              type="text"
              placeholder="Enter destination URL"
              value={overlayURL}
              onChange={(e) => setOverlayURL(e.target.value)}
            />
            <button
              onClick={() => {
                setIsQRCodeModalOpen(false);
                alert('QR Code URL saved!');
              }}
            >
              Save
            </button>
            <button onClick={() => setIsQRCodeModalOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Overlays;
