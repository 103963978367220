// components/Scheduler/Scheduler.jsx

import React, { useState, useRef, useEffect } from 'react';
import './Scheduler.css';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';

function Scheduler({ device }) {
  const [currentView, setCurrentView] = useState('timeGridWeek');
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);

  useEffect(() => {
    // Placeholder for fetching scheduled events from backend
    // For now, we'll use some dummy data
    const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  
    const dummyEvents = [
      {
        id: '1',
        title: 'Morning Playlist',
        start: todayStr + 'T09:00:00',
        end: todayStr + 'T11:00:00',
        backgroundColor: '#FF9500',
        borderColor: '#FF9500',
      },
      {
        id: '2',
        title: 'Lunch Special',
        start: todayStr + 'T12:00:00',
        end: todayStr + 'T13:00:00',
        backgroundColor: '#FF3B30',
        borderColor: '#FF3B30',
      },
      {
        id: '3',
        title: 'Evening Show',
        start: todayStr + 'T18:00:00',
        end: todayStr + 'T20:00:00',
        backgroundColor: '#34C759',
        borderColor: '#34C759',
      },
      {
        id: '4',
        title: 'Daily Announcement',
        rrule: {
          freq: 'daily',
          dtstart: todayStr + 'T08:00:00',
          until: todayStr + 'T08:30:00',
        },
        backgroundColor: '#AF52DE',
        borderColor: '#AF52DE',
      },
    ];
    setEvents(dummyEvents);
  }, []);
  
  const handleDateClick = (arg) => {
    alert('Add New Event at: ' + arg.dateStr + ' (Coming Soon)');
  };

  const handleEventClick = (info) => {
    alert('Event clicked: ' + info.event.title);
    // Open modal to edit event (future enhancement)
  };

  const handleEventDrop = (info) => {
    // Handle event drag and drop (future enhancement)
    alert('Event dropped: ' + info.event.title);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  return (
    <div className="scheduler">
      <h2>Scheduler - {device.name}</h2>
      <div className="scheduler-controls">
        <button
          className={currentView === 'timeGridWeek' ? 'active' : ''}
          onClick={() => handleViewChange('timeGridWeek')}
        >
          Weekly View
        </button>
        <button
          className={currentView === 'dayGridMonth' ? 'active' : ''}
          onClick={() => handleViewChange('dayGridMonth')}
        >
          Monthly View
        </button>
        <button
          onClick={() => {
            alert('Add New Event (Coming Soon)');
        }}
        >
        Add New
        </button>

      </div>
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, rrulePlugin]}
        initialView={currentView}
        headerToolbar={false}
        editable={true}
        droppable={true}
        selectable={true}
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        eventDrop={handleEventDrop}
        ref={calendarRef}
        height="auto"
      />
    </div>
  );
}

export default Scheduler;
