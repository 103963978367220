import React, { useState, useEffect } from 'react';
import './MediaManager.css';

function MediaManager({
  playlistName,
  onSavePlaylist,
  onAddToFallback,
  onLoadToUpNext,
  onUpdatePlaylist,
  onDeletePlaylist,
  onLoadSavedPlaylist,
  savedPlaylists = [],
  searchCategoryOptions = ['all', 'live', 'mix'],
  defaultCategory = 'all',
  limitSearchToLive = false,
  fallbackMode = false,
  existingPlaylist = [],
  adHocMode = false,
  deviceType = 'Music Player',
  categoryFixed = false,
  showLoadToUpNextButton = false
}) {
  function decodeHtmlEntities(str) {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [playlist, setPlaylist] = useState(existingPlaylist);
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const [selectedPlaylistItem, setSelectedPlaylistItem] = useState(null);
  const [selectedSearchResults, setSelectedSearchResults] = useState([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState('Morning');
  const [selectedLength, setSelectedLength] = useState('any');
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const [showPlaylistPopup, setShowPlaylistPopup] = useState(false);

  useEffect(() => {
    if (JSON.stringify(existingPlaylist) !== JSON.stringify(playlist)) {
      setPlaylist(existingPlaylist);
    }
  }, [existingPlaylist]);

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;

    try {
      const params = {
        part: 'snippet',
        q: searchTerm,
        maxResults: 50,
        type: 'video',
        videoEmbeddable: 'true',
        videoSyndicated: 'true',
      };

      if (limitSearchToLive || selectedCategory === 'live') {
        params.eventType = 'live';
      }

      if (selectedCategory === 'mix') {
        params.q += ' mix';
      }

      if (selectedLength !== 'any') {
        params.videoDuration = selectedLength;
      }

      const token = localStorage.getItem('token');
      const response = await fetch(
        `/api/youtube/search?` + new URLSearchParams(params),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      setSearchResults(data || []);
    } catch (error) {
      console.error('Error fetching search results:', error);
      alert('Failed to fetch search results.');
    }
  };

  const addToPlaylist = () => {
    if (selectedSearchResults.length > 0) {
      const newVideos = selectedSearchResults.map((index) => {
        const video = searchResults[index];
        return {
          content_id: video.id.videoId || video.id,
          title: decodeHtmlEntities(video.snippet.title),
          thumbnail_url: video.snippet.thumbnails.default.url,
        };
      });
      if (fallbackMode && onAddToFallback) {
        onAddToFallback(newVideos, selectedTimeOfDay);
      } else {
        const updated = [...playlist, ...newVideos];
        setPlaylist(updated);
        if (adHocMode && onUpdatePlaylist) {
          onUpdatePlaylist(updated);
        }
      }
      setSelectedSearchResults([]);
    }
  };

  const loadToUpNext = () => {
    if (!onLoadToUpNext) return;
    if (selectedSearchResults.length > 0) {
      const video = searchResults[selectedSearchResults[0]];
      const videoData = {
        content_id: video.id.videoId || video.id,
        title: decodeHtmlEntities(video.snippet.title),
        thumbnail_url: video.snippet.thumbnails.default.url,
      };
      onLoadToUpNext(videoData);
      setSelectedSearchResults([]);
    }
  };

  const handleSelectSearchResult = (index) => {
    setSelectedSearchResults((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const removeSelectedFromPlaylist = () => {
    if (selectedPlaylistItem !== null) {
      const updatedPlaylist = playlist.filter((_, index) => index !== selectedPlaylistItem);
      setPlaylist(updatedPlaylist);
      setSelectedPlaylistItem(null);
      if (adHocMode && onUpdatePlaylist) {
        onUpdatePlaylist(updatedPlaylist);
      }
    }
  };

  const moveItemUp = () => {
    if (selectedPlaylistItem > 0) {
      const updatedPlaylist = [...playlist];
      const temp = updatedPlaylist[selectedPlaylistItem - 1];
      updatedPlaylist[selectedPlaylistItem - 1] = updatedPlaylist[selectedPlaylistItem];
      updatedPlaylist[selectedPlaylistItem] = temp;
      setPlaylist(updatedPlaylist);
      setSelectedPlaylistItem(selectedPlaylistItem - 1);
      if (adHocMode && onUpdatePlaylist) {
        onUpdatePlaylist(updatedPlaylist);
      }
    }
  };

  const moveItemDown = () => {
    if (selectedPlaylistItem < playlist.length - 1) {
      const updatedPlaylist = [...playlist];
      const temp = updatedPlaylist[selectedPlaylistItem + 1];
      updatedPlaylist[selectedPlaylistItem + 1] = updatedPlaylist[selectedPlaylistItem];
      updatedPlaylist[selectedPlaylistItem] = temp;
      setPlaylist(updatedPlaylist);
      setSelectedPlaylistItem(selectedPlaylistItem + 1);
      if (adHocMode && onUpdatePlaylist) {
        onUpdatePlaylist(updatedPlaylist);
      }
    }
  };

  const handleSavePlaylist = () => {
    if (!onSavePlaylist) return;
    if (playlist.length === 0) {
      alert('Cannot save an empty playlist.');
      return;
    }
    onSavePlaylist(playlist);
    setPlaylist([]);
  };

  const handleUpdatePlaylist = () => {
    if (!onUpdatePlaylist) return;
    onUpdatePlaylist(playlist);
  };

  const handleDragStart = (index) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedItemIndex === null) return;
    if (draggedItemIndex !== index) {
      const updatedPlaylist = [...playlist];
      const draggedItem = updatedPlaylist.splice(draggedItemIndex, 1)[0];
      updatedPlaylist.splice(index, 0, draggedItem);
      setPlaylist(updatedPlaylist);
      setDraggedItemIndex(index);
      if (adHocMode && onUpdatePlaylist) {
        onUpdatePlaylist(updatedPlaylist);
      }
    }
  };

  const handleDragEnd = () => {
    setDraggedItemIndex(null);
  };

  const handleOpenPopup = () => {
    setShowPlaylistPopup(true);
  };

  const handleSelectSavedPlaylist = (p) => {
    if (onLoadSavedPlaylist) {
      onLoadSavedPlaylist(p);
    }
    setShowPlaylistPopup(false);
  };

  return (
    <div className="media-manager">
      {playlistName && <h3>{playlistName}</h3>}

      <div className="controls-container">
        <div className={`search-section ${fallbackMode ? 'fallback-mode' : ''}`}>
          <div className="search-bar">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search YouTube..."
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            />
            <button onClick={handleSearch}>Search YouTube</button>
          </div>

          <div className="filters-row">
            <div className="category-filters">
              {searchCategoryOptions.map((category) => (
                <button
                  key={category}
                  onClick={() => !categoryFixed && setSelectedCategory(category)}
                  className={selectedCategory === category ? 'active' : ''}
                  disabled={categoryFixed}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </button>
              ))}
            </div>
            <div className="length-filter">
              <label>
                Length:
                <select
                  value={selectedLength}
                  onChange={(e) => setSelectedLength(e.target.value)}
                >
                  <option value="any">Any</option>
                  <option value="short">Short (&lt;4 min)</option>
                  <option value="medium">Medium (4-20 min)</option>
                  <option value="long">Long (&gt;20 min)</option>
                </select>
              </label>
            </div>
          </div>

          <div className="search-actions">
            {fallbackMode && (
              <select
                value={selectedTimeOfDay}
                onChange={(e) => setSelectedTimeOfDay(e.target.value)}
              >
                <option value="Morning">Morning Playlist</option>
                <option value="Afternoon">Afternoon Playlist</option>
                <option value="Evening">Evening Playlist</option>
              </select>
            )}
            <button
              onClick={addToPlaylist}
              disabled={selectedSearchResults.length === 0}
              className="add-to-playlist-button"
            >
              {adHocMode ? 'Add to Ad Hoc Playlist' : 'Add to Playlist'}
            </button>

            {showLoadToUpNextButton && !fallbackMode && !adHocMode && (
              <button
                onClick={loadToUpNext}
                disabled={selectedSearchResults.length === 0}
                className="load-to-upnext-button"
              >
                Load to Up Next
              </button>
            )}
          </div>

          <h4>Search Results</h4>
          <div className="search-results-container">
            {searchResults.length > 0 ? (
              <div className="search-results">
                {searchResults.map((video, index) => (
                  <div
                    key={index}
                    className={`search-item ${selectedSearchResults.includes(index) ? 'selected' : ''}`}
                    onClick={() => handleSelectSearchResult(index)}
                  >
                    <img src={video.snippet.thumbnails.default.url} alt={decodeHtmlEntities(video.snippet.title)} />
                    <span>{decodeHtmlEntities(video.snippet.title)}</span>
                  </div>
                ))}
              </div>
            ) : (
              <p>Search for and select items from the search results, then click "Add to Up Next Playlist" to queue them</p>
            )}
          </div>
        </div>

        <div className="playlist-section">
          <div className="playlist-header">
            <h4>Playlist</h4>
            <button className="dark-blue-button" onClick={handleOpenPopup}>Open</button>
            <button className="green-button" onClick={handleSavePlaylist}>Save As</button>
            <button className="green-button" onClick={handleUpdatePlaylist}>Save</button>
            <button className="red-button" onClick={onDeletePlaylist}>Delete</button>
            <button className="blue-button" onClick={() => setPlaylist([])}>Clear</button>
          </div>
          <div>
            {playlist.length > 0 ? (
              <div className="playlist-items">
                {playlist.map((video, index) => (
                  <div
                    key={index}
                    className={`playlist-item ${selectedPlaylistItem === index ? 'selected' : ''}`}
                    onClick={() => setSelectedPlaylistItem(index)}
                    draggable
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={(e) => {
                      e.preventDefault();
                      handleDragOver(index);
                    }}
                    onDragEnd={handleDragEnd}
                  >
                    <img src={video.thumbnail_url} alt={video.title} />
                    <span>{video.title}</span>
                  </div>
                ))}
              </div>
            ) : (
              <p>No items in the playlist.</p>
            )}
          </div>
          <div className="device-controls">
            <button className="blue-button" onClick={moveItemUp} disabled={selectedPlaylistItem === null}>
              Move Up
            </button>
            <button className="blue-button" onClick={moveItemDown} disabled={selectedPlaylistItem === null}>
              Move Down
            </button>
            <button className="red-button" onClick={removeSelectedFromPlaylist} disabled={selectedPlaylistItem === null}>
              Remove Selected
            </button>
          </div>
        </div>
      </div>

      {showPlaylistPopup && (
        <div className="playlist-popup-backdrop" onClick={() => setShowPlaylistPopup(false)}>
          <div className="playlist-popup-content" onClick={(e) => e.stopPropagation()}>
            <h4>Saved Playlists</h4>
            <div className="playlist-popup-list">
              {savedPlaylists.length > 0 ? (
                savedPlaylists.map((p) => (
                  <div
                    key={p.id}
                    className="popup-playlist-item"
                    onClick={() => handleSelectSavedPlaylist(p)}
                  >
                    <span>{p.name}</span>
                    <span>({p.tracksCount} tracks)</span>
                  </div>
                ))
              ) : (
                <p>No saved playlists found.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MediaManager;
