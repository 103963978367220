// src/hooks/useAdHocPlaylist.js

import { useState, useEffect } from 'react';
import axios from 'axios';

const useAdHocPlaylist = (device) => {
  const [adHocPlaylist, setAdHocPlaylist] = useState([]);
  const [selectedPlaylistItem, setSelectedPlaylistItem] = useState(null);

  const fetchAdHocPlaylist = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/devices/get-ad-hoc-playlist', {
        params: { deviceId: device.id },
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdHocPlaylist(response.data.playlist);
    } catch (error) {
      console.error('Error fetching ad hoc playlist:', error);
    }
  };

  useEffect(() => {
    fetchAdHocPlaylist();
  }, [device.id]);

  const updateAdHocPlaylist = async (newPlaylist) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/api/devices/update-ad-hoc-playlist',
        {
          deviceId: device.id,
          playlist: newPlaylist,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAdHocPlaylist(newPlaylist);
    } catch (error) {
      console.error('Error updating ad hoc playlist:', error);
    }
  };

  return {
    adHocPlaylist,
    setAdHocPlaylist,
    selectedPlaylistItem,
    setSelectedPlaylistItem,
    fetchAdHocPlaylist,
    updateAdHocPlaylist,
  };
};

export default useAdHocPlaylist;
