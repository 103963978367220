// components/Dashboard/VolumeControls.jsx

import React from 'react';

function VolumeControls({ masterVolume, handleMasterVolumeChange, isMuted, handleMuteUnmute, isPlaying, handlePlayPause }) {
  return (
    <div className="volume-controls">
      <label>
        Master Volume:
        <input
          type="range"
          min="0"
          max="100"
          value={masterVolume}
          onChange={handleMasterVolumeChange}
        />
        {masterVolume}%
      </label>
      <button onClick={handleMuteUnmute}>{isMuted ? 'Unmute' : 'Mute'}</button>
      <button className="blue-button" onClick={handlePlayPause} style={{ marginLeft: '10px' }}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
    </div>
  );
}


export default VolumeControls;
