import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import Deck from './Deck';
import Tabs from './Tabs';
import { useSocket } from '../../context/SocketContext';
import useDeckStates from '../../hooks/useDeckStates';
import useDeviceSettings from '../../hooks/useDeviceSettings';
import useAdHocPlaylist from '../../hooks/useAdHocPlaylist';
import VolumeControls from './VolumeControls';
import PlayerSettings from './PlayerSettings';
import MediaManagerSection from './MediaManagerSection';
import axios from 'axios';
import useStatusTimers from '../../hooks/useStatusTimers';

function Dashboard({ device, deviceType }) {
  const socket = useSocket();

  const {
    deckA,
    deckB,
    isPlaying,
    isMuted,
    masterVolume,
    crossfadePosition,
    isMixing,
    playerSettings,
    setDeckA,
    setDeckB,
    setIsPlaying,
    setIsMuted,
    setMasterVolume,
    setCrossfadePosition,
    setIsMixing,
    setPlayerSettings,
  } = useDeckStates(device);

  const {
    mixTime,
    setMixTime,
    preloadAmount,
    setPreloadAmount,
    autoloadDelay,
    setAutoloadDelay,
    updateDeviceSetting,
  } = useDeviceSettings(device);

  const {
    adHocPlaylist,
    setAdHocPlaylist,
    fetchAdHocPlaylist,
    updateAdHocPlaylist,
  } = useAdHocPlaylist(device);

  const [selectedTab, setSelectedTab] = useState('Now Playing');
  const [mixCountdown, setMixCountdown] = useState(null);
  const [autoloadCountdown, setAutoloadCountdown] = useState(null);
  const [settingsChanged, setSettingsChanged] = useState(false);

  const [savedPlaylists, setSavedPlaylists] = useState([]);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const token = localStorage.getItem('token');

  // Fetch saved playlists
  useEffect(() => {
    const fetchPlaylists = async () => {
      if (!device) return;
      try {
        const response = await axios.get(`/api/playlists?deviceType=${deviceType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const playlists = response.data.playlists || [];
        const transformed = playlists.map((p) => ({
          ...p,
          tracksCount: p.items.length,
        }));
        setSavedPlaylists(transformed);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };

    fetchPlaylists();
  }, [device, deviceType, token]);

  const onSavePlaylist = async (playlistItems) => {
    if (playlistItems.length === 0) {
      alert('Cannot save an empty playlist.');
      return;
    }

    const playlistName = prompt('Enter a name for the new playlist:');
    if (playlistName) {
      try {
        const response = await axios.post(
          '/api/playlists',
          {
            name: playlistName,
            items: playlistItems,
            deviceType: deviceType,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        alert('Playlist saved!');
        const newPlaylist = {
          id: response.data.playlistId,
          name: playlistName,
          media_type: deviceType.toLowerCase(),
          items: playlistItems,
          tracksCount: playlistItems.length,
        };
        setSavedPlaylists([...savedPlaylists, newPlaylist]);
        setCurrentPlaylist(newPlaylist);
      } catch (error) {
        console.error('Error saving playlist:', error);
        alert('Failed to save playlist.');
      }
    }
  };

  const onUpdatePlaylist = async (updatedItems) => {
    if (!currentPlaylist) {
      alert('No playlist selected for updating.');
      return;
    }

    try {
      await axios.put(
        `/api/playlists/${currentPlaylist.id}`,
        {
          name: currentPlaylist.name,
          items: updatedItems,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Playlist updated successfully');
      setSavedPlaylists(
        savedPlaylists.map((p) =>
          p.id === currentPlaylist.id ? { ...p, items: updatedItems, tracksCount: updatedItems.length } : p
        )
      );
      setCurrentPlaylist({
        ...currentPlaylist,
        items: updatedItems,
      });
    } catch (error) {
      console.error('Error updating playlist:', error);
      alert('Failed to update playlist.');
    }
  };

  const onDeletePlaylist = async () => {
    if (!currentPlaylist) {
      alert('No playlist selected to delete.');
      return;
    }
    const playlistId = currentPlaylist.id;
    if (!window.confirm('Are you sure you want to delete this playlist?')) return;

    try {
      await axios.delete(`/api/playlists/${playlistId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Playlist deleted successfully');
      setSavedPlaylists(savedPlaylists.filter((p) => p.id !== playlistId));
      setCurrentPlaylist(null);
    } catch (error) {
      console.error('Error deleting playlist:', error);
      alert('Failed to delete playlist.');
    }
  };

  const onLoadSavedPlaylist = (p) => {
    setCurrentPlaylist({
      id: p.id,
      name: p.name,
      items: p.items || [],
    });
  };

  const existingPlaylist = currentPlaylist ? currentPlaylist.items : [];

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = Math.floor(seconds % 60).toString().padStart(2, '0');
    return h !== '00' ? `${h}:${m}:${s}` : `${m}:${s}`;
  };

  const sendCommand = (command, value = null) => {
    if (!socket || !device) return;
    const payload = {
      deviceId: device.id,
      command: command,
      value: value,
    };
    socket.emit('sendCommand', payload);
  };

  const handlePlayPause = () => {
    const command = isPlaying ? 'pause' : 'play';
    sendCommand(command);
    setIsPlaying(!isPlaying);
  };

  const handleCue = () => {
    sendCommand('cue');
    setIsPlaying(false);
  };

  const handleMixNow = () => {
    if (isMixing) return;
    setIsMixing(true);
    sendCommand('mixNow', { mixTime, preloadAmount, autoloadDelay });
  };

  const handleMixTimeChange = (e) => {
    const newMixTime = parseInt(e.target.value);
    if (newMixTime < 1) return;
    setMixTime(newMixTime);
    updateDeviceSetting('mix_time', newMixTime);
    setSettingsChanged(true);
  };

  const handlePreloadAmountChange = (e) => {
    const newPreloadAmount = parseInt(e.target.value);
    if (newPreloadAmount < 0) return;
    setPreloadAmount(newPreloadAmount);
    updateDeviceSetting('preload_amount', newPreloadAmount);
    setSettingsChanged(true);
  };

  const handleAutoloadDelayChange = (e) => {
    const newAutoloadDelay = parseInt(e.target.value);
    if (newAutoloadDelay < 0) return;
    setAutoloadDelay(newAutoloadDelay);
    updateDeviceSetting('autoload_delay', newAutoloadDelay);
    setSettingsChanged(true);
  };

  const handleSettingChange = (settingName, value) => {
    setPlayerSettings({
      ...playerSettings,
      [settingName]: value,
    });
    sendCommand('updateSettings', { [settingName]: value });
  };

  const handleMasterVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    setMasterVolume(newVolume);
    sendCommand('setMasterVolume', { volume: newVolume });
  };

  const handleMuteUnmute = () => {
    const command = isMuted ? 'unmute' : 'mute';
    sendCommand(command);
    setIsMuted(!isMuted);
  };

  const handleAddToAdHocPlaylist = (newPlaylist) => {
    updateAdHocPlaylist(newPlaylist);
  };

  const fetchCurrentDeviceSettings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/devices/get-device-settings', {
        params: { deviceId: device.id },
        headers: { Authorization: `Bearer ${token}` },
      });
      const settings = response.data.settings;
      if (settings.mix_time !== null && settings.mix_time !== undefined) {
        setMixTime(settings.mix_time);
      }
      if (settings.preload_amount !== null && settings.preload_amount !== undefined) {
        setPreloadAmount(settings.preload_amount);
      }
      if (settings.autoload_delay !== null && settings.autoload_delay !== undefined) {
        setAutoloadDelay(settings.autoload_delay);
      }
      setSettingsChanged(false);
    } catch (error) {
      console.error('Error fetching device settings before loadToUpNext:', error);
    }
  };

  const handleLoadToUpNext = async () => {
    try {
      if (settingsChanged) {
        await fetchCurrentDeviceSettings();
      }

      if (adHocPlaylist.length > 0) {
        let videoToLoad = adHocPlaylist[0];
        sendCommand('loadToDeckB', { videoId: videoToLoad.content_id });
        const updatedPlaylist = adHocPlaylist.filter(
          (item) => item.content_id !== videoToLoad.content_id
        );
        setAdHocPlaylist(updatedPlaylist);
        await updateAdHocPlaylist(updatedPlaylist);
        await fetchAdHocPlaylist();
      } else {
        sendCommand('triggerScanAndLoad');
      }
    } catch (error) {
      console.error('Error loading to up next:', error);
    }
  };

  useEffect(() => {
    if (!socket || !device) return;

    const handleDeviceStateUpdate = (data) => {
      const { deviceId, state } = data;
      if (deviceId !== device.id) return;

      setDeckA(state.deckA);
      setDeckB(state.deckB);
      setIsPlaying(state.isPlaying);
      setIsMuted(state.isMuted);
      setMasterVolume(state.masterVolume);
      setCrossfadePosition(state.crossfadePosition);
      setIsMixing(state.isMixing);
      setPlayerSettings(state.playerSettings);

      calculateCountdowns(state);
    };

    socket.on('deviceStateUpdate', handleDeviceStateUpdate);
    socket.emit('requestDeviceState', { deviceId: device.id });

    return () => {
      socket.off('deviceStateUpdate', handleDeviceStateUpdate);
    };
  }, [socket, device]);

  const calculateCountdowns = (state) => {
    if (state && state.deckA && state.deckA.duration > 0) {
      const { duration, currentTime } = state.deckA;
      const remainingTime = duration - currentTime;
  
      const clipTime = 3;
      const clipAutoload = 2;
      const autoloadTime = mixTime + preloadAmount + (autoloadDelay - clipAutoload) + clipTime;
      const automixTime = mixTime;
  
      const autoloadRemaining = remainingTime - autoloadTime;
      const automixRemaining = remainingTime - automixTime;
  
      setAutoloadCountdown(autoloadRemaining > 0 ? autoloadRemaining : 0);
      setMixCountdown(automixRemaining > 0 ? automixRemaining : 0);
    } else {
      setAutoloadCountdown(null);
      setMixCountdown(null);
    }
  };

  const clipAutoload = 2;
  const adjustedAutoloadDelay = autoloadDelay - clipAutoload;

  const { statusText, timeValue } = useStatusTimers({
    isMixing,
    mixCountdown,
    autoloadDelay: adjustedAutoloadDelay,
    preloadAmount,
  });

  return (
    <div className="dashboard">
      <h2>Dashboard - {device.name}</h2>

      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {settingsChanged && (
        <div className="settings-changed-notice" style={{ color: 'blue', marginBottom: '10px' }}>
          Settings will be applied to the next track
        </div>
      )}

      {selectedTab === 'Now Playing' && (
        <div className="now-playing-tab">
          <div className="now-playing-layout">
            <div className="top-row">
              <div className="deck-section left-deck">
                <Deck deckData={deckA} deckLabel="A" />
              </div>
              <div className="center-section">
                <div className="countdown-timers">
                  <p>
                    <strong>{statusText}</strong> {formatTime(timeValue)}
                  </p>
                </div>
                <button
                  className="green-button"
                  onClick={handleMixNow}
                  disabled={isMixing || !deckB.videoId}
                >
                  {isMixing ? 'Mixing...' : deckB.videoId ? 'MIX NOW' : 'Deck Empty'}
                </button>
              </div>
              <div className="deck-section right-deck">
                <Deck
                  deckData={deckB}
                  deckLabel="B"
                  autoloadCountdown={autoloadCountdown}
                />
              </div>
            </div>

            <div className="bottom-row">
              <div className="volume-controls-row">
                <VolumeControls
                  masterVolume={masterVolume}
                  handleMasterVolumeChange={handleMasterVolumeChange}
                  isMuted={isMuted}
                  handleMuteUnmute={handleMuteUnmute}
                  isPlaying={isPlaying}
                  handlePlayPause={handlePlayPause}
                />
              </div>
              <div className="load-upnext-button">
                <button className="green-button" onClick={handleLoadToUpNext} disabled={isMixing}>
                  Load to Up Next
                </button>
              </div>
            </div>
          </div>

          <MediaManagerSection
            handleAddToAdHocPlaylist={handleAddToAdHocPlaylist}
            handleLoadToUpNext={handleLoadToUpNext}
            adHocPlaylist={adHocPlaylist}
            deviceType={deviceType}

            onSavePlaylist={onSavePlaylist}
            onUpdatePlaylist={onUpdatePlaylist}
            onDeletePlaylist={onDeletePlaylist}
            onLoadSavedPlaylist={onLoadSavedPlaylist}
            savedPlaylists={savedPlaylists}
            existingPlaylist={existingPlaylist}
            playlistName={currentPlaylist ? currentPlaylist.name : 'New Playlist'}
            showLoadToUpNextButton={false}
          />
        </div>
      )}

      {selectedTab === 'Player Settings' && (
        <div className="player-settings-tab">
          <PlayerSettings
            playerSettings={playerSettings}
            onSettingChange={handleSettingChange}
            mixTime={mixTime}
            preloadAmount={preloadAmount}
            autoloadDelay={autoloadDelay}
            handleMixTimeChange={handleMixTimeChange}
            handlePreloadAmountChange={handlePreloadAmountChange}
            handleAutoloadDelayChange={handleAutoloadDelayChange}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
