// utils/formatTime.js

export function formatTime(seconds) {
  if (!seconds || isNaN(seconds)) return '00:00';
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  if (h > 0) {
    return `${padZero(h)}:${padZero(m)}:${padZero(s)}`;
  } else {
    return `${padZero(m)}:${padZero(s)}`;
  }
}

function padZero(num) {
  return num < 10 ? '0' + num : num.toString();
}
