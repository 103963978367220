import React from 'react';
import MediaManager from '../MediaManager/MediaManager';

function MediaManagerSection({ handleAddToAdHocPlaylist, handleLoadToUpNext, adHocPlaylist, deviceType }) {
  return (
    <div className="media-manager-section">
      <MediaManager
        playlistName="Up Next Playlist"
        adHocMode={true}
        existingPlaylist={adHocPlaylist}
        onUpdatePlaylist={handleAddToAdHocPlaylist}
        onLoadToUpNext={handleLoadToUpNext}
        deviceType={deviceType}
        categoryFixed={false}
      />
    </div>
  );
}

export default MediaManagerSection;
