// components/Announcements/Announcements.jsx

import React, { useState, useEffect } from 'react';
import './Announcements.css';
import axios from 'axios';

function Announcements({ selectedDevice }) {
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [customAnnouncementText, setCustomAnnouncementText] = useState('');
  const [voiceStyle, setVoiceStyle] = useState('UK Male');
  const [isCreating, setIsCreating] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!selectedDevice) return;

    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements', { // Updated API path
          headers: { Authorization: `Bearer ${token}` },
        });
        setAnnouncements(response.data);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        alert('Failed to fetch announcements.');
      }
    };

    fetchAnnouncements();
  }, [selectedDevice, token]);

  const handleAnnouncementSelect = (announcement) => {
    setSelectedAnnouncement(announcement);
    setCustomAnnouncementText(announcement.text);
    setVoiceStyle(announcement.voiceStyle || 'UK Male');
    setIsCreating(false);
  };

  const handleCreateNew = () => {
    setSelectedAnnouncement(null);
    setCustomAnnouncementText('');
    setVoiceStyle('UK Male');
    setIsCreating(true);
  };

  const handleSaveAnnouncement = async () => {
    if (isCreating) {
      // Create new announcement
      try {
        const response = await axios.post(
          '/api/announcements', // Updated API path
          { text: customAnnouncementText, voiceStyle },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setAnnouncements([...announcements, response.data]);
        alert('Announcement created successfully!');
        setIsCreating(false);
        setCustomAnnouncementText('');
      } catch (error) {
        console.error('Error creating announcement:', error);
        alert('Failed to create announcement.');
      }
    } else if (selectedAnnouncement) {
      // Update existing announcement
      try {
        await axios.put(
          `/api/announcements/${selectedAnnouncement.id}`, // Updated API path
          { text: customAnnouncementText, voiceStyle },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setAnnouncements(
          announcements.map((ann) =>
            ann.id === selectedAnnouncement.id
              ? { ...ann, text: customAnnouncementText, voiceStyle }
              : ann
          )
        );
        alert('Announcement updated successfully!');
      } catch (error) {
        console.error('Error updating announcement:', error);
        alert('Failed to update announcement.');
      }
    }
  };

  return (
    <div className="announcements-section">
      <h3>Announcements</h3>
      <div className="announcements-container">
        <div className="announcements-list">
          <h4>Existing Announcements</h4>
          <ul>
            {announcements.map((announcement) => (
              <li
                key={announcement.id}
                className={
                  selectedAnnouncement && selectedAnnouncement.id === announcement.id
                    ? 'selected'
                    : ''
                }
                onClick={() => handleAnnouncementSelect(announcement)}
              >
                {announcement.text}
              </li>
            ))}
          </ul>
          <button onClick={handleCreateNew}>Create New Announcement</button>
        </div>

        <div className="announcement-details">
          <h4>{isCreating ? 'Create Announcement' : 'Edit Announcement'}</h4>
          <textarea
            placeholder="Enter announcement text here..."
            value={customAnnouncementText}
            onChange={(e) => setCustomAnnouncementText(e.target.value)}
          />
          <label>
            Voice Style:
            <select
              value={voiceStyle}
              onChange={(e) => setVoiceStyle(e.target.value)}
            >
              <option value="UK Male">UK Male</option>
              <option value="UK Female">UK Female</option>
              <option value="Aus Male">Aus Male</option>
              <option value="Aus Female">Aus Female</option>
              <option value="US Male">US Male</option>
              <option value="US Female">US Female</option>
            </select>
          </label>
          <div className="announcement-buttons">
            <button onClick={handleSaveAnnouncement}>
              {isCreating ? 'Create' : 'Save'}
            </button>
            {!isCreating && selectedAnnouncement && (
              <button
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this announcement?')) {
                    // Implement delete functionality if needed
                    alert('Delete functionality not implemented yet.');
                  }
                }}
                className="delete-button"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcements;
