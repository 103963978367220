// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Signup from './pages/Signup';
import Login from './pages/Login';
import ControlPanel from './pages/ControlPanel';
import Home from './pages/Home';
import { SocketProvider } from './context/SocketContext'; // Adjust the import path as needed
import axios from 'axios';

// Helper function to check if the user is authenticated
const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

// Define the PrivateRoute component
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <SocketProvider isAuthenticated={isAuthenticated}>
            <Component {...props} />
          </SocketProvider>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

function App() {
  const [auth, setAuth] = useState({
    isAuthenticated: isAuthenticated(),
  });

  // Check authentication status on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    setAuth({ isAuthenticated: !!token });
  }, []);

  // Axios interceptor for handling 401 errors
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        handleLogout();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  // Handle login
  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setAuth({
      isAuthenticated: true,
    });
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth({
      isAuthenticated: false,
    });
  };

  return (
    <Router>
      <nav>
        <ul>
          {!auth.isAuthenticated ? (
            <>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/signup">Signup</Link></li>
              <li><Link to="/login">Login</Link></li>
            </>
          ) : (
            <>
              <li><Link to="/control-panel">Control Panel</Link></li>
              <li><Link to="/" onClick={handleLogout}>Sign Out</Link></li>
            </>
          )}
        </ul>
      </nav>

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/signup" component={Signup} />
        {/* Pass the handleLogin function to the Login component */}
        <Route path="/login" component={() => <Login onLogin={handleLogin} />} />
        <PrivateRoute
          path="/control-panel"
          component={ControlPanel}
          isAuthenticated={auth.isAuthenticated}
        />
      </Switch>
    </Router>
  );
}

export default App;
