import React, { useState, useEffect, useMemo } from 'react';
import './PlaylistLibrary.css';
import MediaManager from '../MediaManager';
import axios from 'axios';

function PlaylistLibrary({ selectedDevice }) {
  const [playlists, setPlaylists] = useState([]);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const token = localStorage.getItem('token');
  const deviceType = selectedDevice ? selectedDevice.type : 'Music Player';

  useEffect(() => {
    if (!selectedDevice) return;

    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(`/api/playlists?deviceType=${deviceType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const pls = response.data.playlists || [];
        const transformed = pls.map((p) => ({
          ...p,
          tracksCount: p.items.length,
        }));
        setPlaylists(transformed);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        alert('Failed to fetch playlists.');
      }
    };

    fetchPlaylists();
  }, [selectedDevice, deviceType, token]);

  const saveNewPlaylist = async (playlistItems) => {
    if (playlistItems.length === 0) {
      alert('Cannot save an empty playlist.');
      return;
    }

    const playlistName = prompt('Enter a name for the new playlist:');
    if (playlistName) {
      try {
        const response = await axios.post(
          '/api/playlists',
          {
            name: playlistName,
            items: playlistItems,
            deviceType: deviceType,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        alert('Playlist saved!');
        const newPlaylist = {
          id: response.data.playlistId,
          name: playlistName,
          media_type: deviceType.toLowerCase(),
          items: playlistItems,
          tracksCount: playlistItems.length,
        };
        setPlaylists([...playlists, newPlaylist]);
      } catch (error) {
        console.error('Error saving playlist:', error);
        alert('Failed to save playlist.');
      }
    }
  };

  const handleEditPlaylist = (playlist) => {
    setCurrentPlaylist(playlist);
  };

  const handleUpdatePlaylist = async (updatedItems) => {
    if (!currentPlaylist) return;

    try {
      await axios.put(
        `/api/playlists/${currentPlaylist.id}`,
        {
          name: currentPlaylist.name,
          items: updatedItems,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Playlist updated successfully');
      setPlaylists(
        playlists.map((p) =>
          p.id === currentPlaylist.id ? { ...p, items: updatedItems, tracksCount: updatedItems.length } : p
        )
      );
      setCurrentPlaylist(null);
    } catch (error) {
      console.error('Error updating playlist:', error);
      alert('Failed to update playlist.');
    }
  };

  const handleDeletePlaylist = async (playlistId) => {
    if (!window.confirm('Are you sure you want to delete this playlist?')) return;

    try {
      await axios.delete(`/api/playlists/${playlistId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Playlist deleted successfully');
      setPlaylists(playlists.filter((p) => p.id !== playlistId));
      if (currentPlaylist && currentPlaylist.id === playlistId) {
        setCurrentPlaylist(null);
      }
    } catch (error) {
      console.error('Error deleting playlist:', error);
      alert('Failed to delete playlist.');
    }
  };

  const saveToGlobalDefaults = async () => {
    try {
      await axios.post(
        '/api/playlists/global-defaults',
        { playlists },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Global defaults have been updated!');
    } catch (error) {
      console.error('Error saving global defaults:', error);
      alert('Failed to save global defaults.');
    }
  };

  const existingPlaylist = useMemo(
    () => (currentPlaylist ? currentPlaylist.items : []),
    [currentPlaylist]
  );

  const onLoadSavedPlaylist = (p) => {
    // Load this playlist into currentPlaylist
    const selected = playlists.find((pl) => pl.id === p.id);
    if (selected) {
      setCurrentPlaylist({
        id: selected.id,
        name: selected.name,
        items: selected.items
      });
    }
  };

  return (
    <div className="playlist-library">
      <h3>Search for New Content</h3>
      <p>
        Search YouTube for Videos, Mixes, and Live Streams, and save them in a playlist to be
        scheduled.
      </p>
      <MediaManager
        playlistName={currentPlaylist ? currentPlaylist.name : 'New Playlist'}
        existingPlaylist={existingPlaylist}
        onSavePlaylist={saveNewPlaylist}
        onUpdatePlaylist={currentPlaylist ? handleUpdatePlaylist : null}
        onDeletePlaylist={
          currentPlaylist ? () => handleDeletePlaylist(currentPlaylist.id) : null
        }
        fallbackMode={false}
        deviceType={deviceType}
        categoryFixed={false}
        savedPlaylists={playlists}
        onLoadSavedPlaylist={onLoadSavedPlaylist}
        showLoadToUpNextButton={false} 
      />
      <button onClick={saveToGlobalDefaults}>Save to Global Default</button>
      <h3>Saved Playlists</h3>
      {playlists.length > 0 ? (
        <div className="playlists-table-container">
          <table className="playlists-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Tracks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {playlists.map((playlist) => (
                <tr key={playlist.id}>
                  <td>{playlist.name}</td>
                  <td>{playlist.items.length}</td>
                  <td>
                    <button onClick={() => handleEditPlaylist(playlist)}>Edit</button>
                    <button onClick={() => handleDeletePlaylist(playlist.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No playlists available.</p>
      )}
    </div>
  );
}

export default PlaylistLibrary;
