import React from 'react';
import './PlayerSettings.css';
import languageOptions from './languageOptions';

function PlayerSettings({
  playerSettings,
  onSettingChange,
  mixTime,
  preloadAmount,
  autoloadDelay,
  handleMixTimeChange,
  handlePreloadAmountChange,
  handleAutoloadDelayChange
}) {
  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    onSettingChange(name, checked ? 1 : 0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onSettingChange(name, value);
  };

  return (
    <div className="player-settings">
      <h3>Player Settings</h3>

      {/* Display Settings */}
      <div className="settings-section">
        <h4>Display Settings</h4>
        <div className="setting-item">
          <label>Player Controls:</label>
          <select
            name="controls"
            value={playerSettings.controls}
            onChange={handleInputChange}
          >
            <option value="0">None</option>
            <option value="1">Default</option>
            <option value="2">Auto-Hide</option>
          </select>
        </div>
        <div className="setting-item">
          <label>Minimize Branding:</label>
          <input
            type="checkbox"
            name="modestbranding"
            checked={playerSettings.modestbranding === 1}
            onChange={handleToggleChange}
          />
        </div>
        <div className="setting-item">
          <label>Fullscreen Option:</label>
          <input
            type="checkbox"
            name="fs"
            checked={playerSettings.fs === 1}
            onChange={handleToggleChange}
          />
        </div>
        <div className="setting-item">
          <label>Progress Bar Color:</label>
          <select
            name="color"
            value={playerSettings.color}
            onChange={handleInputChange}
          >
            <option value="red">Red</option>
            <option value="white">White</option>
          </select>
        </div>
      </div>

      {/* Playback Settings */}
      <div className="settings-section">
        <h4>Playback Settings</h4>
        <div className="setting-item">
          <label>Autoplay:</label>
          <input
            type="checkbox"
            name="autoplay"
            checked={playerSettings.autoplay === 1}
            onChange={handleToggleChange}
          />
        </div>
        <div className="setting-item">
          <label>Loop Video:</label>
          <input
            type="checkbox"
            name="loop"
            checked={playerSettings.loop === 1}
            onChange={handleToggleChange}
          />
        </div>
        <div className="setting-item">
          <label>Play Inline on Mobile:</label>
          <input
            type="checkbox"
            name="playsinline"
            checked={playerSettings.playsinline === 1}
            onChange={handleToggleChange}
          />
        </div>
      </div>

      {/* Accessibility & Subtitles */}
      <div className="settings-section">
        <h4>Accessibility & Subtitles</h4>
        <div className="setting-item">
          <label>Show CC by Default:</label>
          <input
            type="checkbox"
            name="cc_load_policy"
            checked={playerSettings.cc_load_policy === 1}
            onChange={handleToggleChange}
          />
        </div>
        <div className="setting-item">
          <label>CC Language:</label>
          <select
            name="cc_lang_pref"
            value={playerSettings.cc_lang_pref}
            onChange={handleInputChange}
          >
            {languageOptions.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Interaction Controls */}
      <div className="settings-section">
        <h4>Interaction Controls</h4>
        <div className="setting-item">
          <label>Disable Keyboard Controls:</label>
          <input
            type="checkbox"
            name="disablekb"
            checked={playerSettings.disablekb === 1}
            onChange={handleToggleChange}
          />
        </div>
        <div className="setting-item">
          <label>Video Annotations:</label>
          <select
            name="iv_load_policy"
            value={playerSettings.iv_load_policy}
            onChange={handleInputChange}
          >
            <option value="1">Show</option>
            <option value="3">Hide</option>
          </select>
        </div>
      </div>

      {/* Language Settings */}
      <div className="settings-section">
        <h4>Language Settings</h4>
        <div className="setting-item">
          <label>Interface Language:</label>
          <select
            name="hl"
            value={playerSettings.hl}
            onChange={handleInputChange}
          >
            {languageOptions.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Mix & Load Settings (New Section) */}
      <div className="settings-section">
        <h4>Mix & Load Settings</h4>
        <div className="setting-item">
          <label>
            Mix Time (seconds):<br />
            <small>The time it takes to Crossfade to Up Next</small>
          </label>
          <input
            type="number"
            min="1"
            max="60"
            value={mixTime}
            onChange={handleMixTimeChange}
          />
        </div>

        <div className="setting-item">
          <label>
            Preload Amount (seconds):<br />
            <small>Pre-Play the video after loading to Up Next, to ensure it is buffered</small>
          </label>
          <input
            type="number"
            min="0"
            max="180"
            value={preloadAmount}
            onChange={handlePreloadAmountChange}
          />
        </div>

        <div className="setting-item">
          <label>
            Autoload Delay (seconds):<br />
            <small>Delay before the next video is loaded to Up Next</small>
          </label>
          <input
            type="number"
            min="0"
            max="180"
            value={autoloadDelay}
            onChange={handleAutoloadDelayChange}
          />
        </div>
      </div>

    </div>
  );
}

export default PlayerSettings;
