// src/hooks/useDeviceSettings.js

import { useState, useEffect } from 'react';
import axios from 'axios';

const useDeviceSettings = (device) => {
  const [mixTime, setMixTime] = useState(5); // Default mix time in seconds
  const [preloadAmount, setPreloadAmount] = useState(3); // Preload Amount in seconds
  const [autoloadDelay, setAutoloadDelay] = useState(3); // Autoload Delay in seconds

  useEffect(() => {
    const fetchDeviceSettings = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/devices/get-device-settings', {
          params: { deviceId: device.id },
          headers: { Authorization: `Bearer ${token}` },
        });
        const settings = response.data.settings;
        if (settings.mix_time !== null && settings.mix_time !== undefined) {
          setMixTime(settings.mix_time);
        }
        if (settings.preload_amount !== null && settings.preload_amount !== undefined) {
          setPreloadAmount(settings.preload_amount);
        }
        if (settings.autoload_delay !== null && settings.autoload_delay !== undefined) {
          setAutoloadDelay(settings.autoload_delay);
        }
      } catch (error) {
        console.error('Error fetching device settings:', error);
      }
    };

    fetchDeviceSettings();
  }, [device.id]);

  const updateDeviceSetting = async (settingName, value) => {
    try {
      const token = localStorage.getItem('token');
      const payload = {
        deviceId: device.id,
        settings: { [settingName]: value },
      };
      await axios.post('/api/devices/update-settings', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error(`Error updating device setting ${settingName}:`, error);
    }
  };

  // Enforce the constraint that autoloadDelay >= mixTime + preloadAmount
  //useEffect(() => {
  //  const minAutoloadDelay = mixTime + preloadAmount;
  //  if (autoloadDelay < minAutoloadDelay) {
  //    setAutoloadDelay(minAutoloadDelay);
  //    updateDeviceSetting('autoload_delay', minAutoloadDelay);
  //  }
  //}, [mixTime, preloadAmount]);

  return {
    mixTime,
    setMixTime,
    preloadAmount,
    setPreloadAmount,
    autoloadDelay,
    setAutoloadDelay,
    updateDeviceSetting,
  };
};

export default useDeviceSettings;
