// src/context/SocketContext.js

import React, { createContext, useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children, isAuthenticated }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem('token');

      const newSocket = io('https://soundscreen.soundcheckvn.com', {
        path: '/socket.io',
        transports: ['websocket'],
        auth: {
          userToken: token,
        },
      });

      newSocket.on('connect', () => {
        console.log('Socket connected:', newSocket.id);
      });

      newSocket.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    } else {
      // Disconnect any existing socket connection
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    }
  
  }, [isAuthenticated]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
