// src/hooks/useStatusTimers.js
import { useState, useEffect, useRef } from 'react';

export default function useStatusTimers({ isMixing, mixCountdown, autoloadDelay, preloadAmount }) {
  const [phase, setPhase] = useState('mixing_in');
  const [autoloadCounter, setAutoloadCounter] = useState(0);
  const [preloadCounter, setPreloadCounter] = useState(0);
  const autoloadIntervalRef = useRef(null);
  const preloadIntervalRef = useRef(null);
  const [wasMixing, setWasMixing] = useState(false);

  useEffect(() => {
    if (isMixing && !wasMixing) {
      setWasMixing(true);
      setPhase('mixing_now');
    } else if (!isMixing && wasMixing) {
      // Mixing just finished, start autoload countdown
      setWasMixing(false);
      startAutoloadCountdown();
    }
  }, [isMixing, wasMixing]);

  const startAutoloadCountdown = () => {
    if (preloadIntervalRef.current) clearInterval(preloadIntervalRef.current);
    setPreloadCounter(0);
    setAutoloadCounter(autoloadDelay);
    setPhase('autoloading');

    if (autoloadIntervalRef.current) clearInterval(autoloadIntervalRef.current);
    autoloadIntervalRef.current = setInterval(() => {
      setAutoloadCounter(count => {
        if (count > 0) {
          return count - 1;
        } else {
          clearInterval(autoloadIntervalRef.current);
          startPreloading();
          return 0;
        }
      });
    }, 1000);
  };

  const startPreloading = () => {
    setPhase('preloading');
    setPreloadCounter(0);

    if (preloadIntervalRef.current) clearInterval(preloadIntervalRef.current);
    preloadIntervalRef.current = setInterval(() => {
      setPreloadCounter(count => {
        if (count < preloadAmount) {
          return count + 1;
        } else {
          clearInterval(preloadIntervalRef.current);
          // Return to mixing_in phase
          setPhase('mixing_in');
          return preloadAmount;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (autoloadIntervalRef.current) clearInterval(autoloadIntervalRef.current);
      if (preloadIntervalRef.current) clearInterval(preloadIntervalRef.current);
    };
  }, []);

  let statusText = '';
  let timeValue = 0;

  if (phase === 'mixing_in') {
    // If we're not currently mixing_now but have a countdown
    if (isMixing) {
      statusText = 'Mixing Now...';
    } else if (mixCountdown !== null && mixCountdown > 0) {
      statusText = 'Mixing In:';
      timeValue = mixCountdown;
    } else {
      statusText = 'Mixing In:';
      timeValue = 0;
    }
  }

  if (phase === 'mixing_now') {
    statusText = 'Mixing Now...';
    // timeValue can remain 0 here
  }

  if (phase === 'autoloading') {
    statusText = 'Autoloading in:';
    timeValue = autoloadCounter;
  }

  if (phase === 'preloading') {
    statusText = 'Preloading:';
    timeValue = preloadCounter;
  }

  return { statusText, timeValue };
}
