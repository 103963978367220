import React, { useState, useEffect, useMemo } from 'react';
import './FallbackPlaylist.css';
import MediaManager from '../MediaManager';
import axios from 'axios';

function FallbackPlaylist({ selectedDevice }) {
  const [morningPlaylist, setMorningPlaylist] = useState([]);
  const [afternoonPlaylist, setAfternoonPlaylist] = useState([]);
  const [eveningPlaylist, setEveningPlaylist] = useState([]);
  const [selectedTrackIndices, setSelectedTrackIndices] = useState({
    morning: null,
    afternoon: null,
    evening: null,
  });

  const [savedPlaylists, setSavedPlaylists] = useState([]);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const token = localStorage.getItem('token');
  const deviceId = selectedDevice ? selectedDevice.id : null;
  const deviceType = selectedDevice ? selectedDevice.type : 'Music Player';

  // Fetch fallback playlists
  useEffect(() => {
    if (!deviceId) return;

    const fetchFallbackPlaylists = async () => {
      try {
        const response = await axios.get(`/api/playlists/fallback?deviceId=${deviceId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setMorningPlaylist(data.morning || []);
        setAfternoonPlaylist(data.afternoon || []);
        setEveningPlaylist(data.evening || []);
      } catch (error) {
        console.error('Error fetching fallback playlists:', error);
        alert('Failed to fetch fallback playlists.');
      }
    };

    fetchFallbackPlaylists();
  }, [deviceId, token]);

  // Fetch saved playlists for Open button
  useEffect(() => {
    if (!deviceType) return;

    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(`/api/playlists?deviceType=${deviceType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const playlists = response.data.playlists || [];
        const transformed = playlists.map((p) => ({
          ...p,
          tracksCount: p.items.length,
        }));
        setSavedPlaylists(transformed);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };

    fetchPlaylists();
  }, [deviceType, token]);

  const handleAddToFallback = (videos, timeOfDay) => {
    const newVideos = videos.map((video) => ({
      content_id: video.content_id,
      title: video.title,
      thumbnail_url: video.thumbnail_url,
    }));

    if (timeOfDay === 'Morning') {
      setMorningPlaylist([...morningPlaylist, ...newVideos]);
    } else if (timeOfDay === 'Afternoon') {
      setAfternoonPlaylist([...afternoonPlaylist, ...newVideos]);
    } else if (timeOfDay === 'Evening') {
      setEveningPlaylist([...eveningPlaylist, ...newVideos]);
    }
  };

  const handleSaveFallbackPlaylists = async () => {
    const playlists = {
      morning: morningPlaylist,
      afternoon: afternoonPlaylist,
      evening: eveningPlaylist,
    };

    try {
      await axios.post(
        '/api/playlists/fallback',
        { deviceId, playlists },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Fallback Playlists have been saved!');
    } catch (error) {
      console.error('Error saving fallback playlists:', error);
      alert('Failed to save fallback playlists.');
    }
  };

  const selectTrack = (timeOfDay, index) => {
    setSelectedTrackIndices((prev) => ({
      ...prev,
      [timeOfDay]: prev[timeOfDay] === index ? null : index,
    }));
  };

  const moveTrackUp = (timeOfDay) => {
    const index = selectedTrackIndices[timeOfDay];
    if (index === null || index === 0) return;

    let playlist = [];
    if (timeOfDay === 'Morning') {
      playlist = [...morningPlaylist];
    } else if (timeOfDay === 'Afternoon') {
      playlist = [...afternoonPlaylist];
    } else if (timeOfDay === 'Evening') {
      playlist = [...eveningPlaylist];
    }

    [playlist[index - 1], playlist[index]] = [playlist[index], playlist[index - 1]];

    if (timeOfDay === 'Morning') {
      setMorningPlaylist(playlist);
    } else if (timeOfDay === 'Afternoon') {
      setAfternoonPlaylist(playlist);
    } else if (timeOfDay === 'Evening') {
      setEveningPlaylist(playlist);
    }

    setSelectedTrackIndices((prev) => ({
      ...prev,
      [timeOfDay]: index - 1,
    }));
  };

  const moveTrackDown = (timeOfDay) => {
    const index = selectedTrackIndices[timeOfDay];
    let playlistLength = 0;
    if (timeOfDay === 'Morning') {
      playlistLength = morningPlaylist.length;
    } else if (timeOfDay === 'Afternoon') {
      playlistLength = afternoonPlaylist.length;
    } else if (timeOfDay === 'Evening') {
      playlistLength = eveningPlaylist.length;
    }

    if (index === null || index === playlistLength - 1) return;

    let playlist = [];
    if (timeOfDay === 'Morning') {
      playlist = [...morningPlaylist];
    } else if (timeOfDay === 'Afternoon') {
      playlist = [...afternoonPlaylist];
    } else if (timeOfDay === 'Evening') {
      playlist = [...eveningPlaylist];
    }

    [playlist[index + 1], playlist[index]] = [playlist[index], playlist[index + 1]];

    if (timeOfDay === 'Morning') {
      setMorningPlaylist(playlist);
    } else if (timeOfDay === 'Afternoon') {
      setAfternoonPlaylist(playlist);
    } else if (timeOfDay === 'Evening') {
      setEveningPlaylist(playlist);
    }

    setSelectedTrackIndices((prev) => ({
      ...prev,
      [timeOfDay]: index + 1,
    }));
  };

  const removeTrack = (timeOfDay) => {
    const index = selectedTrackIndices[timeOfDay];
    if (index === null) return;

    let playlist = [];
    if (timeOfDay === 'Morning') {
      playlist = [...morningPlaylist];
    } else if (timeOfDay === 'Afternoon') {
      playlist = [...afternoonPlaylist];
    } else if (timeOfDay === 'Evening') {
      playlist = [...eveningPlaylist];
    }

    playlist.splice(index, 1);

    if (timeOfDay === 'Morning') {
      setMorningPlaylist(playlist);
    } else if (timeOfDay === 'Afternoon') {
      setAfternoonPlaylist(playlist);
    } else if (timeOfDay === 'Evening') {
      setEveningPlaylist(playlist);
    }

    setSelectedTrackIndices((prev) => ({
      ...prev,
      [timeOfDay]: null,
    }));
  };

  // Handlers for the MediaManager operations (Open, Save As, Save, Delete, Clear)
  const onSavePlaylist = async (playlistItems) => {
    if (playlistItems.length === 0) {
      alert('Cannot save an empty playlist.');
      return;
    }

    const playlistName = prompt('Enter a name for the new playlist:');
    if (playlistName) {
      try {
        const response = await axios.post(
          '/api/playlists',
          {
            name: playlistName,
            items: playlistItems,
            deviceType: deviceType,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        alert('Playlist saved!');
        const newPlaylist = {
          id: response.data.playlistId,
          name: playlistName,
          media_type: deviceType.toLowerCase(),
          items: playlistItems,
          tracksCount: playlistItems.length,
        };
        setSavedPlaylists([...savedPlaylists, newPlaylist]);
        setCurrentPlaylist(newPlaylist);
      } catch (error) {
        console.error('Error saving playlist:', error);
        alert('Failed to save playlist.');
      }
    }
  };

  const onUpdatePlaylist = async (updatedItems) => {
    if (!currentPlaylist) {
      alert('No playlist selected for updating.');
      return;
    }

    try {
      await axios.put(
        `/api/playlists/${currentPlaylist.id}`,
        {
          name: currentPlaylist.name,
          items: updatedItems,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Playlist updated successfully');
      setSavedPlaylists(
        savedPlaylists.map((p) =>
          p.id === currentPlaylist.id ? { ...p, items: updatedItems, tracksCount: updatedItems.length } : p
        )
      );
      setCurrentPlaylist({
        ...currentPlaylist,
        items: updatedItems,
      });
    } catch (error) {
      console.error('Error updating playlist:', error);
      alert('Failed to update playlist.');
    }
  };

  const onDeletePlaylist = async () => {
    if (!currentPlaylist) {
      alert('No playlist selected to delete.');
      return;
    }
    const playlistId = currentPlaylist.id;
    if (!window.confirm('Are you sure you want to delete this playlist?')) return;

    try {
      await axios.delete(`/api/playlists/${playlistId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Playlist deleted successfully');
      setSavedPlaylists(savedPlaylists.filter((p) => p.id !== playlistId));
      setCurrentPlaylist(null);
    } catch (error) {
      console.error('Error deleting playlist:', error);
      alert('Failed to delete playlist.');
    }
  };

  const onLoadSavedPlaylist = (p) => {
    setCurrentPlaylist({
      id: p.id,
      name: p.name,
      items: p.items || [],
    });
  };

  const existingPlaylist = useMemo(
    () => (currentPlaylist ? currentPlaylist.items : []),
    [currentPlaylist]
  );

  return (
    <div className="fallback-playlist">
      <h3>Fallback Playlists</h3>
      <button onClick={handleSaveFallbackPlaylists}>Save Playlists</button>
      <div className="playlists-container">
        {['Morning', 'Afternoon', 'Evening'].map((timeOfDay) => {
          const playlist =
            timeOfDay === 'Morning'
              ? morningPlaylist
              : timeOfDay === 'Afternoon'
              ? afternoonPlaylist
              : eveningPlaylist;
          const selectedIndex = selectedTrackIndices[timeOfDay];

          return (
            <div key={timeOfDay} className="playlist-section">
              <h4>{timeOfDay}</h4>
              {playlist.length > 0 ? (
                <ul>
                  {playlist.map((video, index) => (
                    <li
                      key={index}
                      className={selectedIndex === index ? 'selected' : ''}
                      onClick={() => selectTrack(timeOfDay, index)}
                    >
                      {video.title}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No items in {timeOfDay} Playlist.</p>
              )}
              <div className="playlist-controls">
                <button
                  onClick={() => moveTrackUp(timeOfDay)}
                  disabled={selectedIndex === null || selectedIndex === 0}
                >
                  Move Up
                </button>
                <button
                  onClick={() => moveTrackDown(timeOfDay)}
                  disabled={
                    selectedIndex === null ||
                    selectedIndex === playlist.length - 1 ||
                    playlist.length === 0
                  }
                >
                  Move Down
                </button>
                <button
                  onClick={() => removeTrack(timeOfDay)}
                  disabled={selectedIndex === null}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="add-stream-section">
        <h3>Add Content to Fallback Playlists</h3>
        <p>
          Search and add content from YouTube for when nothing else is scheduled.
        </p>
        <MediaManager
          playlistName={currentPlaylist ? currentPlaylist.name : 'New Playlist'}
          existingPlaylist={existingPlaylist}
          onSavePlaylist={onSavePlaylist}
          onUpdatePlaylist={currentPlaylist ? onUpdatePlaylist : null}
          onDeletePlaylist={currentPlaylist ? onDeletePlaylist : null}
          onLoadSavedPlaylist={onLoadSavedPlaylist}
          savedPlaylists={savedPlaylists}
          fallbackMode={true}
          deviceType={deviceType}
          categoryFixed={deviceType !== 'TV'}
          onAddToFallback={handleAddToFallback}
        />
      </div>
    </div>
  );
}

export default FallbackPlaylist;
