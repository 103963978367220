import React from 'react';
import { formatTime } from '../../utils/formatTime'; // Ensure this import is correct

function Deck({ deckData, deckLabel, autoloadCountdown }) {
  return (
    <div 
      className={`deck deck-${deckLabel.toLowerCase()}`}
      style={{
        width: '100%',
        minHeight: '450px', // slightly taller to accommodate larger artwork
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        padding: '10px'
      }}
    >
      <h3 style={{ marginBottom: '10px' }}>{`Deck ${deckLabel} - ${deckLabel === 'A' ? 'Now Playing' : 'Up Next'}`}</h3>
      <div 
        className="deck-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          flex: '1 0 auto'
        }}
      >
        {deckData.videoId ? (
          <>
            <div 
              className="track-info" 
              style={{ marginBottom: '10px', textAlign: 'center', minHeight: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <p style={{ margin: 0 }}>{deckData.title}</p>
            </div>
            
            {/* Increased artwork size by ~35%: from 320x180 to about 432x243 */}
            <div 
              className="artwork-container" 
              style={{
                width: '432px', 
                height: '243px', 
                backgroundColor: '#000', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                marginBottom: '10px'
              }}
            >
              <img
                src={`https://i.ytimg.com/vi/${deckData.videoId}/hqdefault.jpg`}
                alt={`Deck ${deckLabel} Artwork`}
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
              />
            </div>

            <div 
              className="deck-controls" 
              style={{
                textAlign: 'center', 
                minHeight: '40px', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center'
              }}
            >
              <p style={{ margin: 0 }}>
                Time: {formatTime(deckData.currentTime)} / {formatTime(deckData.duration)}
              </p>
              <p style={{ margin: 0 }}>
                Remaining: {formatTime(deckData.duration - deckData.currentTime)}
              </p>
            </div>
          </>
        ) : (
          <div 
            className="placeholder-content"
            style={{
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              width: '432px', 
              height: '283px', // slightly taller to match space
              backgroundColor: '#333', 
              color: '#fff', 
              textAlign: 'center'
            }}
          >
            <div className="placeholder-image" style={{ marginBottom: '10px' }}>No Content Loaded</div>
            {autoloadCountdown !== null && (
              <p style={{ margin: 0 }}>Autoloading in: {formatTime(autoloadCountdown)}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Deck;
