// components/AreaOverview/AreaOverview.jsx

import React, { useEffect, useState } from 'react';
import './AreaOverview.css';

function AreaOverview({ area, onDeviceSelect }) {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    // Fetch devices in the selected area
    const fetchDevices = async () => {
      const token = localStorage.getItem('token');
      if (!area) return;

      try {
        const response = await fetch(`/api/areas/${area.id}/devices`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setDevices(data.devices || []);
        } else {
          console.error('Failed to load devices.');
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, [area]);

  return (
    <div className="area-overview">
      <h2>{area ? `${area.name} Overview` : 'Area Overview'}</h2>
      {devices.length > 0 ? (
        <div className="device-cards">
          {devices.map((device) => (
            <div key={device.id} className="device-card" onClick={() => onDeviceSelect(device.id)}>
              <img src={device.thumbnail || '/default-thumbnail.png'} alt={device.name} />
              <h3>{device.name}</h3>
              <p>Now Playing: Placeholder</p>
              <p>Next Up: Placeholder</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No devices found in this area.</p>
      )}
    </div>
  );
}

export default AreaOverview;
