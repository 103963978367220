// src/hooks/useDeckStates.js

import { useState, useEffect } from 'react';
import { useSocket } from '../context/SocketContext';

function useDeckStates(device) {
  const socket = useSocket();
  const [deckA, setDeckA] = useState({});
  const [deckB, setDeckB] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [masterVolume, setMasterVolume] = useState(100);
  const [crossfadePosition, setCrossfadePosition] = useState(50);
  const [isMixing, setIsMixing] = useState(false);
  const [playerSettings, setPlayerSettings] = useState({});

  useEffect(() => {
    if (!socket || !device) return;

    const handleDeviceStateUpdate = (data) => {
      const { deviceId, state } = data;
      if (deviceId !== device.id) return;

      // Update states
      setDeckA(state.deckA);
      setDeckB(state.deckB);
      setIsPlaying(state.isPlaying);
      setIsMuted(state.isMuted);
      setMasterVolume(state.masterVolume);
      setCrossfadePosition(state.crossfadePosition);
      setIsMixing(state.isMixing);
      setPlayerSettings(state.playerSettings);
    };

    socket.on('deviceStateUpdate', handleDeviceStateUpdate);

    // Request the current device state when the hook is initialized
    socket.emit('requestDeviceState', { deviceId: device.id });

    return () => {
      socket.off('deviceStateUpdate', handleDeviceStateUpdate);
    };
  }, [socket, device]);

  return {
    deckA,
    deckB,
    isPlaying,
    isMuted,
    masterVolume,
    crossfadePosition,
    isMixing,
    playerSettings,
    setDeckA,          // Add this
    setDeckB,          // Add this
    setIsPlaying,
    setIsMuted,
    setMasterVolume,
    setCrossfadePosition,
    setIsMixing,
    setPlayerSettings,
  };
}

export default useDeckStates;
