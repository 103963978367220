// components/Events/Events.jsx

import React, { useState, useEffect } from 'react';
import './Events.css';
import eventsData from '../../eventsData.json';

function Events() {
  const [selectedCategory, setSelectedCategory] = useState('Events');
  const [selectedEvent, setSelectedEvent] = useState(null);

  const categories = ['Events', 'Content', 'Games', 'Addons'];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedEvent(null);
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  return (
    <div className="events">
      <h2>Events</h2>
      <div className="event-categories">
        {categories.map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? 'active' : ''}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="event-content">
        <div className="event-list">
          {eventsData[selectedCategory].map((event) => (
            <div
              key={event.id}
              className="event-card"
              onClick={() => handleEventSelect(event)}
            >
              <img src={event.thumbnail || '/images/placeholder.jpg'} alt={event.title} />
              <h3>{event.title}</h3>
            </div>
          ))}
        </div>

        {selectedEvent && (
          <div className="event-details">
            <h3>{selectedEvent.title}</h3>
            <p>{selectedEvent.description}</p>
            <button
              onClick={() => window.open('https://www.youtube.com/watch?v=nbmJnCHXaW8', '_blank')}
            >
              Preview
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Events;
